import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneServiceSection";

const ServiceSection = () => {
    const [data, setData] = useState({});
    const [serviceData, setServiceData] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}`)
            .then(res => {
                setData(res.data);
                setServiceData(res.data.serviceData);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <section className="section service-area overflow-hidden ptb_100">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-4 pt-lg-0">
                            <h2 className="text-capitalize mb-4">{data.heading}</h2>
                            {/* Service List */}
                            <ul className="service-list">
                                {serviceData.map((item, idx) => (
                                    <li key={`so_${idx}`} className="single-service media py-2">
                                        <div className="service-icon pr-4">
                                            <span><i className={item.iconClass} /></span>
                                        </div>
                                        <div className="service-text media-body">
                                            <p>{item.text}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <a href="/faq" className="btn btn-bordered mt-4">{data.buttonText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block">
                        {/* Service Thumb */}
                        <div className="service-thumb mx-auto">
                            <img src={data.thumbTwo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceSection;