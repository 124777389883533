import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server-1/themeOneFooterSection";

const FooterSection = () => {
    const [data, setData] = useState({});
    const [footerList_4, setFooterList_4] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(BASE_URL)
            .then(res => {
                setData(res.data);
                setFooterList_4(res.data.footerList_4);
            })
            .catch(err => {
                setError('Failed to load data');
                console.error(err);
            });
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <footer className="section inner-footer bg-gray ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Footer Items */}
                        <div className="footer-items text-center">
                            {/* Logo */}
                            <a className="navbar-brand" href="/#">
                                <img className="logo" src={data.image} alt="Logo" />
                            </a>
                            <p className="mt-2 mb-3">{data.text}</p>
                            <ul className="d-flex flex-column flex-sm-row justify-content-between">
                                {footerList_4.map((item, idx) => (
                                    <li key={`fl_${idx}`} className="py-2"><a href="/#">{item.text}</a></li>
                                ))}
                            </ul>
                            {/* Copyright Area */}
                            <div className="copyright-area border-0 pt-3">
                                <p>Made with <i className="fas fa-heart" /> By <a target="_blank" rel="noopener noreferrer" href="https://themeforest.net/user/theme_land">Themeland</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterSection;