import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneFaqSection";

const FaqSection = () => {
    const [data, setData] = useState({});
    const [faqData, setFaqData] = useState([]);
    const [faqDataTwo, setFaqDataTwo] = useState([]);

    useEffect(() => {
        axios.get(BASE_URL)
            .then(res => {
                setData(res.data);
                setFaqData(res.data.faqData);
                setFaqDataTwo(res.data.faqDataTwo);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <section className="section faq-area style-two ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{data.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{data.headingTexttwo}</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12">
                        {/* FAQ Content */}
                        <div className="faq-content">
                            {/* sApp Accordion */}
                            <div className="accordion" id="sApp-accordion">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {faqData.map((item, idx) => (
                                            <div key={`fo_${idx}`} className="card border-0">
                                                {/* Card Header */}
                                                <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                </div>
                                                {/* Card Body */}
                                                <div className="card-body px-0 py-3">
                                                    {item.content}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {faqDataTwo.map((item, idx) => (
                                            <div key={`ft_${idx}`} className="card border-0">
                                                {/* Card Header */}
                                                <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                </div>
                                                {/* Card Body */}
                                                <div className="card-body px-0 py-3">
                                                    {item.content}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <p className="text-body text-center pt-4 fw-5">
                                        {data.faqText} <a href="/contact-page">{data.faqTextLink}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqSection;