import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneFaqSection";

const FaqSection = () => {
    const [data, setData] = useState({});
    const [faqDataThree, setFaqDataThree] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        axios.get(BASE_URL)
            .then(res => {
                setData(res.data);
                setFaqDataThree(res.data.faqDataThree);
            })
            .catch(err => console.log(err));
    }, []);

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <section className="section faq-area ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{data.headingTwo}</h2>
                            <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{data.headingTexttwo}</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12">
                        {/* FAQ Content */}
                        <div className="faq-content">
                            {/* sApp Accordion */}
                            <div className="accordion" id="sApp-accordion">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-10 col-lg-8">
                                        {/* Single Accordion Item */}
                                        {faqDataThree.map((item, idx) => (
                                            <div key={`ft_${idx}`} className="card border-top-0 border-left-0 border-right-0 border-bottom">
                                                {/* Card Header */}
                                                <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button
                                                            className="btn px-0 py-3"
                                                            type="button"
                                                            onClick={() => handleToggle(idx)}
                                                        >
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div
                                                    className={`collapse ${activeIndex === idx ? 'show' : ''}`}
                                                    id={`collapse${idx}`}
                                                >
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqSection;