import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneFeatureSection";

const FeatureSection = () => {
    const [data, setData] = useState({});
    const [featureDataTwo, setFeatureDataTwo] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(BASE_URL)
            .then(res => {
                setData(res.data);
                setFeatureDataTwo(res.data.featureDataTwo);
            })
            .catch(err => {
                setError('Failed to load data');
                console.error(err);
            });
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <section id="features" className="section features-area ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2>{data.headingTwo}</h2>
                            <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{data.headingTexttwo}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {featureDataTwo.map((item, idx) => (
                        <div key={`ft_${idx}`} className="col-12 col-md-6 col-lg-4">
                            {/* Icon Box */}
                            <div className="icon-box text-center p-4">
                                {/* Featured Icon */}
                                <div className="featured-icon mb-3">
                                    <span className={item.iconClass} />
                                </div>
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">{item.title}</h3>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default FeatureSection;