import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server-1/themeOneFooterSection";

const FooterSection = () => {
    const [data, setData] = useState({});
    const [iconList, setIconList] = useState([]);
    const [footerList_1, setFooterList_1] = useState([]);
    const [footerList_2, setFooterList_2] = useState([]);
    const [footerList_3, setFooterList_3] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(BASE_URL)
            .then(res => {
                setData(res.data);
                setIconList(res.data.iconList);
                setFooterList_1(res.data.footerList_1);
                setFooterList_2(res.data.footerList_2);
                setFooterList_3(res.data.footerList_3);
            })
            .catch(err => {
                setError('Failed to load data');
                console.error(err);
            });
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div className="height-emulator d-none d-lg-block" />
            <footer className="footer-area footer-fixed">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Logo */}
                                    <a className="navbar-brand" href="/#">
                                        <img className="logo" src={data.image} alt="" />
                                    </a>
                                    <p className="mt-2 mb-3">{data.text}</p>
                                    {/* Social Icons */}
                                    <div className="social-icons d-flex">
                                        {iconList.map((item, idx) => (
                                            <a key={`fi_${idx}`} className={item.link} href="/#">
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data.linkText_1}</h3>
                                    <ul>
                                        {footerList_1.map((item, idx) => (
                                            <li key={`flo_${idx}`} className="py-2"><a href="/#">{item.text}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data.linkText_2}</h3>
                                    <ul>
                                        {footerList_2.map((item, idx) => (
                                            <li key={`flt_${idx}`} className="py-2"><a href="/#">{item.text}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title mb-2">{data.linkText_3}</h3>
                                    {/* Store Buttons */}
                                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                                        {footerList_3.map((item, idx) => (
                                            <a key={`flth_${idx}`} href="/#">
                                                <img src={item.image} alt="" />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left">© Copyrights 2024 sApp All rights reserved.</div>
                                    {/* Copyright Right */}
                                    <div className="copyright-right">Made with <i className="fas fa-heart" /> By <a target="_blank" rel="noopener noreferrer" href="https://themeforest.net/user/theme_land">Themeland</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default FooterSection;