import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneFeatureSection";

const FeatureSection = () => {
    const [data, setData] = useState({});
    const [featureData, setFeatureData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`${BASE_URL}`)
            .then(res => {
                setData(res.data);
                setFeatureData(res.data.featureData);
            })
            .catch(err => {
                setError('Failed to load data');
                console.error(err);
            });
    }, []);

    return (
        <section id="features" className="section features-area style-two overflow-hidden ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                                <i className="far fa-lightbulb text-primary mr-1" />
                                <span className="text-primary">{data.preHeading}</span> {data.preHeadingspan}
                            </span>
                            <h2>{data.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{data.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{data.headingTexttwo}</p>
                        </div>
                    </div>
                </div>
                {error ? ( // Show error message if there's an error
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <p className="text-danger">{error}</p>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        {featureData.map((item, idx) => (
                            <div key={`fo_${idx}`} className="col-12 col-md-6 col-lg-4 res-margin">
                                {/* Image Box */}
                                <div className="image-box text-center icon-1 p-5">
                                    {/* Featured Image */}
                                    <div className="featured-img mb-3">
                                        <img className="avatar-sm" src={item.image} alt={item.title} />
                                    </div>
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
}

export default FeatureSection;